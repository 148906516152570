import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BlockContent from '../components/block-content'

export default function HomePage({ data }) {
  const content = data?.sanityHomePage?.content

  return (
    <Layout>
      <SEO title="Home" />
      <BlockContent blocks={content} />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    sanityHomePage {
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`
